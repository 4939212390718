export default [
  {
    title: 'Tableau de bord',
    route: 'espace-utilisateur.dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Faire une demande',
    route: 'espace-utilisateur.faire-demande',
    icon: 'PlusIcon',
  },
  {
    header: 'Gestion des demandes',
    icon: 'ListIcon',
  },
  {
    title: 'Etat des demandes',
    icon: 'ListIcon',
    children: [
      {
        title: 'Demandes soumises',
        color: 'primary',
        icon: 'DiscIcon',

        route: 'espace-utilisateur.list-demandes-soumises',

      },
      {
        title: 'Demandes en cours',
        route: 'espace-utilisateur.list-demandes-encours',
        icon: 'DiscIcon',

        color: 'warning',
      },
      {
        title: 'Demandes traitées',
        color: 'success',
        icon: 'DiscIcon',

        route: 'espace-utilisateur.list-demandes-traitees',

      },
      {
        title: 'Demandes rejetées',
        color: 'danger',
        icon: 'DiscIcon',

        route: 'espace-utilisateur.list-demandes-rejetees',

      },
    ],
  },

]
